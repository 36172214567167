import React from "react"
import Layout from "../../components/layout/layout"
import SEO from "../../components/seo"
import logo from "../../assets/css/images/bloglogo.png"
import BlogSidebar from "../../components/blog-sidebar"
import { articles } from "../blog"

export default () => (
  <Layout isHome={false} pageId="blogpage" logo={logo}>
    <SEO title="Crafting a Design Persona" />
    <div id="content">
      <ul id="content_article">
        <li
          id="post-115"
          className="post-115 post type-post status-publish format-standard hentry category-blog"
        >
          <h1>UX for the Enterprise</h1>
          <p className="postdetails">
            Posted on <span>November 18, 2014</span>
          </p>
          <div className="wrap_blog_text">
            <div className="contact-intro">
              Every product has a personality—whether it was deliberately
              designed to or not. Reddit is quirky, hyperactive, and sometimes
              sarcastic. Amazon is like a salesperson with an eidetic memory and
              amazing talent for statistics.
            </div>
            <p>
              That’s enterprise UX.
              <br />
              Yes, those 50,000 people use your software because they don’t have
              a choice. And sure, that completely predictable technology stack
              is ten years out-of-date. But, despite its quirks, doing UX work
              for enterprise clients is an opportunity to spread good design to
              the industries that need it most.
            </p>
            <p>
              Working with enterprise clients can be an exercise in frustration,
              filled with endless meetings and labyrinthine bureaucracy. It can
              also be immensely rewarding, with unique challenges and creatively
              satisfying work. As designers, we live to solve problems, and few
              problems are larger than those lurking in the inner depths of a
              global organization.
            </p>
            <h3>Enterprise work</h3>
            <p>
              Enterprise UX is often about solving ancillary problems by
              creating tools that facilitate an organization’s primary goals.
              These problems are rarely as compelling or visible as the goals
              they support, but they’re just as necessary to solve. A company
              might build the best-designed cars in the world, but it won’t
              matter if its quality-assurance process is hobbled by unusable
              software. Good design enables enterprises to do the work they were
              founded to do.
            </p>
            <p>
              Enterprise employees are also consumers, and they’ve come to
              expect consumer-level design in all the tools they use. Why
              shouldn’t a company’s inventory software or HR portal be as
              polished as Evernote, Pinterest, or Instagram? When a consumer app
              is poorly designed, the user can delete it. When an enterprise app
              is poorly designed, its users are stuck with it.
            </p>
            <p>
              The stakes can be enormously high. The sheer scale of enterprise
              clients magnifies the effects of good and bad design alike. Small
              inefficiencies in large organizations result in extra costs that
              are passed on to the end user in time spent, money lost, and
              frustration increased.
            </p>{" "}
          </div>
        </li>
      </ul>

      <BlogSidebar articles={articles} />
    </div>
  </Layout>
)
